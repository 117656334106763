<template>
  <v-dialog v-model="showDialog" persistent>
    <v-card>
      <v-card-title>
        <slot name="title">Attention!</slot>
      </v-card-title>
      <v-card-text>
        <slot></slot>
      </v-card-text>
      <v-card-actions>
        <slot name="ok-button">
          <v-btn text @click="$emit('cancel')">
            Annuleren
          </v-btn>
        </slot>
        <v-spacer></v-spacer>
        <slot name="cancel-button">
          <v-btn text @click="$emit('ok')">
            OK
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "OkCancelDialog",
  props: {
    showDialog: {
      type: Boolean
    },
    title: {
      type: String
    },
    text: {
      type: String
    }
  }
};
</script>

<style scoped></style>
