<template>
  <v-container>
    <h2 class="mb-4">{{ currentStep }}</h2>
    <div class="d-flex align-center">
      <v-icon
          v-if="!fetchingMerchant"
          :color="merchantValid ? 'green' : 'grey'"
          large
      >
        {{ checkIcon }}
      </v-icon>
      <div v-else style="height: 36px; width: 36px; display: inline-block;">
        <v-progress-circular indeterminate color="grey"></v-progress-circular>
      </div>
      <span class="check-span">Handelaar</span>
    </div>
    <div class="d-flex align-center">
      <v-icon
          v-if="!fetchingGiftCard"
          :color="merchantValid && giftCardValid ? 'green' : 'grey'"
          large
      >
        {{ checkIcon }}
      </v-icon>
      <div v-else style="height: 36px; width: 36px; display: inline-block;">
        <v-progress-circular indeterminate color="grey"></v-progress-circular>
      </div>
      <span class="check-span">Giftcard</span>
      <span class="ml-2" style="font-size: 1.5em" v-if="giftCardValid"
      >({{ (giftCardAmount / 100) | currency }})</span
      >
    </div>
    <v-btn @click="reset()" class="mt-4" style="width: 100%">Opnieuw scannen</v-btn>
    <div style="position: relative;">
      <qrcode-stream @decode="onDecode" @init="onInit" :camera="fetchingGiftCard || fetchingMerchant || showingDialog || doingTransaction || (merchantValid && giftCardValid) ? 'off' : chosenCamera" class="mt-4 mb-4">
        <div v-if="loadingCamera || cameraPermissionIssue || cameraGeneralIssue" class="camera-overlay flex-column px-4">
          <v-icon x-large>{{ noCameraIcon }}</v-icon>
          <div class="d-flex flex-row align-center justify-center">
            <span v-if="loadingCamera">Camera wordt geladen...</span>
            <span v-if="cameraPermissionIssue" class="red--text">Camera toestemming is vereist voor deze app.</span>
            <span v-if="cameraGeneralIssue" class="red--text">Er ging iets mis bij het laden van de camera.</span>
          </div>
        </div>
        <div v-else-if="fetchingGiftCard || fetchingMerchant" class="camera-overlay px-4">
          <v-progress-circular indeterminate color="white"></v-progress-circular>
          <h3 class="ml-4 white--text">Even geduld...</h3>
        </div>
      </qrcode-stream>
    </div>
    <OkCancelDialog
        :show-dialog="showTransactionConfirmationDialog"
        title="Bevestigen"
        @ok="processTransaction"
        @cancel="setShowTransactionConfirmationDialog(false)"
    >
      Bent u zeker dat u {{ amountToDeduct | currency }} wil afschrijven?
    </OkCancelDialog>
    <OkDialog
        :show-dialog="showInactiveGiftCardDialog"
        title="Ongeldige giftcard"
        @ok="setState({ showInactiveGiftCardDialog: false })"
    >
      Deze giftcard is niet meer actief.
    </OkDialog>
    <OkDialog
        :show-dialog="showGiftCardScannedAsMerchantDialog"
        title="Ongeldige handelarenkaart"
        @ok="setState({ showGiftCardScannedAsMerchantDialog: false })"
    >
      Een giftcard kan niet gebruikt worden als handelarenkaart.
    </OkDialog>
    <OkDialog
        :show-dialog="showMerchantScannedAsGiftCardDialog"
        title="Ongeldige giftcard"
        @ok="setState({ showMerchantScannedAsGiftCardDialog: false })"
    >
      Een handelarenkaart kan niet gebruikt worden als giftcard.
    </OkDialog>
    <OkDialog
        :show-dialog="showInvalidMerchantScanDialog"
        title="Ongeldige handelarenkaart"
        @ok="setState({ showInvalidMerchantScanDialog: false })"
    >
      Deze kaart is niet herkend als een geldige handelarenkaart.
    </OkDialog>
    <OkDialog
        :show-dialog="showInvalidGiftCardScanDialog"
        title="Ongeldige giftcard"
        @ok="setState({ showInvalidGiftCardScanDialog: false })"
    >
      Deze kaart is niet herkend als een geldige giftcard.
    </OkDialog>
    <OkDialog
        :show-dialog="showGenericErrorDialog"
        title="Probleem opgetreden"
        @ok="setState({ showGenericErrorDialog: false })"
    >
      Er ging iets mis bij uw aanvraag, probeer het later opnieuw.
    </OkDialog>
    <OkDialog
        :show-dialog="showShopNotMatchingDialog"
        title="Ongeldige giftcard"
        @ok="setState({ showShopNotMatchingDialog: false })"
    >
      Deze giftcard is niet geldig voor deze handelaar.
    </OkDialog>
    <OkDialog
        :show-dialog="showTransactionAmountTooHighDialog"
        title="Saldo ontoereikend"
        @ok="setState({ showTransactionAmountTooHighDialog: false })"
    >
      Het saldo op deze giftcard is ontoereikend. Scan de kaart opnieuw om zijn
      waarde opnieuw op te halen.
    </OkDialog>
    <OkDialog
        :show-dialog="showTransactionSuccessfulDialog"
        title="Succes!"
        @ok="
        setState({ showTransactionSuccessfulDialog: false });
        resetGiftCard();
      "
    >
      Er is succesvol {{ amountToDeduct | currency }} van de kaart afgeschreven.
    </OkDialog>
    <OkDialog
        :show-dialog="showTransactionFailedDialog"
        title="Mislukt"
        @ok="setState({ showTransactionFailedDialog: false })"
    >
      Er ging iets mis bij het uitvoeren van de transactie, probeer later
      opnieuw.
    </OkDialog>
  </v-container>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import OkDialog from "@/components/OkDialog";
import OkCancelDialog from "@/components/OkCancelDialog";
import { mdiCheckBold, mdiCameraOff } from "@mdi/js";

export default {
  name: "Home",
  data: () => ({
    checkIcon: mdiCheckBold,
    noCameraIcon: mdiCameraOff,
    loadingCamera: true,
    cameraPermissionIssue: false,
    cameraGeneralIssue: false,
    chosenCamera: "rear"
  }),
  components: {
    OkDialog,
    OkCancelDialog
    // ValidationProvider,
    // ValidationObserver
  },
  methods: {
    onDecode(decodedString) {
      if (this.fetchingMerchant || this.fetchingGiftCard) return;
      if (this.merchantValid) this.setGiftCardCode(decodedString);
      else this.setMerchantCode(decodedString);
    },
    async onInit(promise) {
      console.log("Init");
      try {
        await promise;
        console.log("init done");
        this.loadingCamera = false;

        // successfully initialized
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.cameraPermissionIssue = true;
          this.loadingCamera = false;
          // user denied camera access permisson
        } else if (error.name === "OverconstrainedError") {
          // did you requested the front camera although there is none?
          console.log("Camera overconstrained, trying default")
          this.chosenCamera = "auto";
        } else {
          // browser seems to be lacking features
          this.cameraGeneralIssue = true;
          this.loadingCamera = false;
        }
      } finally {
        // hide loading indicator
      }
    },
    reset() {
      this.loadingCamera = true;
      this.chosenCamera = "off";
      setTimeout(() => this.chosenCamera = "rear")
      this.resetState();
    },
    ...mapActions([
      "setMerchantCode",
      "setGiftCardCode",
      "resetState",
      "processTransaction",
      "resetGiftCard"
    ]),
    ...mapMutations([
      "setShowTransactionConfirmationDialog",
      "setState",
      "setAmountToDeduct"
    ])
  },
  computed: {
    currentStep() {
      if (!this.merchantValid) {
        return "Scan de handelaarskaart";
      } else if (this.merchantValid && !this.giftCardValid) {
        return "Scan de giftcard";
      }
      return "Vul het bedrag in";
    },
    ...mapState([
      "merchantValid",
      "giftCardValid",
      "showTransactionConfirmationDialog",
      "showGiftCardScannedAsMerchantDialog",
      "showInvalidMerchantScanDialog",
      "showInvalidGiftCardScanDialog",
      "showShopNotMatchingDialog",
      "showGenericErrorDialog",
      "fetchingGiftCard",
      "fetchingMerchant",
      "giftCardAmount",
      "showTransactionSuccessfulDialog",
      "showTransactionAmountTooHighDialog",
      "doingTransaction",
      "showMerchantScannedAsGiftCardDialog",
      "amountToDeduct",
      "showTransactionFailedDialog",
      "showInactiveGiftCardDialog"
    ]),
    ...mapGetters([ "showingDialog" ])
  }
};
</script>

<style lang="stylus">
.check-span
  font-size 1.5em
.camera-overlay
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.2);
  display: flex;
  justify-content: center;
  align-items: center;
</style>
