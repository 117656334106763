import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import VueQrcodeReader from "vue-qrcode-reader";
import axios from "axios";
import { currency } from "@/filters/currency";

Vue.use(VueQrcodeReader);
Vue.filter("currency", currency);

Vue.config.productionTip = false;

axios.defaults.baseURL = "api/qr";

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  i18n,
  mounted: () => document.dispatchEvent(new Event("x-app-rendered"))
}).$mount("#app");
