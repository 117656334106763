import Vue from "vue";
import Vuex from "vuex";
import {
  checkMerchant,
  checkGiftCard,
  sendTransaction
} from "@/api/qr-validator";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    packageVersion: process.env.PACKAGE_VERSION || '0',
    headerTitle: "Giftcard scanner",
    merchantCode: "",
    fetchingMerchant: false,
    merchantValid: false,
    giftCardCode: "",
    fetchingGiftCard: false,
    giftCardValid: false,
    showTransactionConfirmationDialog: false,
    showGiftCardScannedAsMerchantDialog: false,
    showInvalidMerchantScanDialog: false,
    showMerchantScannedAsGiftCardDialog: false,
    showShopNotMatchingDialog: false,
    showInvalidGiftCardScanDialog: false,
    showTransactionFailedDialog: false,
    amountToDeduct: NaN,
    giftCardAmount: 0,
    showTransactionSuccessfulDialog: false,
    showTransactionAmountTooHighDialog: false,
    showGenericErrorDialog: false,
    doingTransaction: false,
    showInactiveGiftCardDialog: false,
  },
  getters: {
    showingDialog: state => state.showGenericErrorDialog 
        || state.showGiftCardScannedAsMerchantDialog 
        || state.showMerchantScannedAsGiftCardDialog 
        || state.showTransactionFailedDialog
        || state.showTransactionConfirmationDialog
        || state.showInvalidMerchantScanDialog
        || state.showShopNotMatchingDialog
        || state.showInvalidGiftCardScanDialog
        || state.showTransactionSuccessfulDialog
        || state.showTransactionAmountTooHighDialog
        || state.showInactiveGiftCardDialog
  },
  mutations: {
    setState(state, payload) {
      state = Object.assign(state, payload);
    },
    setHeaderTitle(state, value) {
      state.headerTitle = value;
    },
    setMerchantCode(state, value) {
      state.merchantCode = value;
    },
    setGiftCardCode(state, value) {
      state.giftCardCode = value;
    },
    setMerchantValid(state, value) {
      state.merchantValid = value;
    },
    setFetchingGiftCard(state, value) {
      state.fetchingGiftCard = value;
    },
    setGiftCardValid(state, value) {
      state.giftCardValid = value;
    },
    setShowTransactionConfirmationDialog(state, value) {
      state.showTransactionConfirmationDialog = value;
    },
    setShowTransactionFailedDialog(state, value) {
      state.showTransactionFailedDialog = value;
    },
    setShowInvalidMerchantScanDialog(state, value) {
      state.showInvalidMerchantScanDialog = value;
    },
    setShowInvalidGiftCardScanDialog(state, value) {
      state.showInvalidGiftCardScanDialog = value;
    },
    setAmountToDeduct(state, value) {
      console.log("Setting amount " + value)
      state.amountToDeduct = parseFloat(value);
    }
  },
  actions: {
    setHeaderTitle({ commit }, value) {
      commit("setHeaderTitle", value);
    },
    setMerchantCode({ commit, dispatch }, value) {
      commit("setMerchantCode", value);
      if (value === "") return;
      dispatch("getMerchantValidation", value);
    },
    setMerchantValid({ commit }) {
      commit("setMerchantValid", true);
    },
    setMerchantInvalid({ commit }) {
      commit("setMerchantValid", false);
    },
    setGiftCardCode({ commit, dispatch }, value) {
      commit("setGiftCardCode", value);
      if (value === "") return;
      dispatch("getGiftCardValidation", value);
    },
    setGiftCardValid({ commit }) {
      commit("setGiftCardValid", true);
    },
    setGiftCardInvalid({ commit }) {
      commit("setGiftCardValid", false);
    },
    resetGiftCard({ commit, dispatch }) {
      dispatch("setGiftCardInvalid");
      dispatch("setGiftCardCode", "")
      commit("setState", { amountToDeduct: NaN, giftCardAmount: 0 })
    },
    async getMerchantValidation({ commit, dispatch }, merchantCode) {
      console.log("Validating merchant: " + merchantCode)
      commit("setState", { fetchingMerchant: true });
      try {
        const data = await checkMerchant(merchantCode);
        console.log("Validation successfully called: ", data)
        
        if (!data) {
          commit("setState", { showInvalidMerchantScanDialog: true });
          dispatch("setMerchantInvalid");
          dispatch("resetGiftCard");
        } else dispatch("setMerchantValid");
      } catch (err) {
        console.log("Validation resulted in error: ", err)
        if (!err.response) {
          commit("setState", { showGenericErrorDialog: true });
          dispatch("setMerchantInvalid");
          dispatch("resetGiftCard");
        } else if (err.response.status === 400) {
          commit("setState", { showGiftCardScannedAsMerchantDialog: true });
          dispatch("setMerchantInvalid");
          dispatch("resetGiftCard");
        } else if (err.response.status === 422) {
          commit("setState", { showInvalidMerchantScanDialog: true });
          dispatch("setMerchantInvalid");
          dispatch("resetGiftCard");
        } else {
          commit("setState", { showGenericErrorDialog: true });
          dispatch("setMerchantInvalid");
          dispatch("resetGiftCard");
        }
      }
      commit("setState", { fetchingMerchant: false });
    },
    async getGiftCardValidation({ state, commit, dispatch }, giftCardCode) {
      console.log("Validating giftcard: ", giftCardCode)
      if (!state.merchantValid) return;
      commit("setFetchingGiftCard", true);
      try {
        const data = await checkGiftCard(state.merchantCode, giftCardCode);
        console.log("Validation successfully called: ", data)

        if (!data) {
          commit("setState", { showInvalidGiftCardScanDialog: true });
          dispatch("resetGiftCard");
        } else {
          dispatch("setGiftCardValid");
          commit("setState", { giftCardAmount: data.amount });
        }
      } catch (err) {
        console.log("Validation resulted in error: ", err)
        if (!err.response) {
          commit("setState", { showGenericErrorDialog: true });
          dispatch("setMerchantInvalid");
          dispatch("resetGiftCard");
          commit("setFetchingGiftCard", false);
          return;
        }

        console.log(err.response)

        let errorCode = -1;
        if (err.response.data && err.response.data.errorCode) {
          errorCode = err.response.data.errorCode;
        }

        if (errorCode === -1) {
          commit("setState", { showGenericErrorDialog: true });
          dispatch("resetGiftCard");
          commit("setFetchingGiftCard", false);
          return;
        }

        if (err.response.status === 400) {
          console.log("400 error " + errorCode)
          if (errorCode === 450) {
            console.log("merchant as giftcard")
            commit("setState", { showMerchantScannedAsGiftCardDialog: true });
            dispatch("resetGiftCard");
          } else if (errorCode === 451) {
            commit("setState", { showShopNotMatchingDialog: true });
            dispatch("resetGiftCard");
          } else if (errorCode === 452) {
            commit("setState", { showInactiveGiftCardDialog: true });
            dispatch("resetGiftCard");
          }
        } else if (err.response.status === 422) {
          commit("setState", { showInvalidGiftCardScanDialog: true });
          dispatch("resetGiftCard");
        } else {
          commit("setState", { showGenericErrorDialog: true });
          dispatch("resetGiftCard");
        }
      }
      commit("setFetchingGiftCard", false);
    },
    async processTransaction({ state, commit, dispatch }) {
      commit("setState", {
        showTransactionConfirmationDialog: false,
        doingTransaction: true
      })
      try {
        console.log("Processing transaction: ", state.merchantCode, state.giftCardCode, Math.floor(state.amountToDeduct * 100))
        const data = await sendTransaction(
          state.merchantCode,
          state.giftCardCode,
          Math.floor(state.amountToDeduct * 100)
        );
        
        console.log("Transaction response: ", data)

        if (!data) {
          commit("setState", {
            showGenericErrorDialog: true
          });
          dispatch("resetGiftCard") // Might not be needed but just in case
        }

        if (data === 130)
          commit("setState", { 
            showTransactionAmountTooHighDialog: true,
          });
        else if (data === 200) {
          commit("setState", { 
            showTransactionSuccessfulDialog: true,
          });
        } else {
          commit("setState", { 
            showTransactionFailedDialog: true,
          });
        }
      } catch (err) {
        console.log("Transaction resulted in error: ", err)
        commit("setState", { 
          showGenericErrorDialog: true,
        });
        dispatch("resetGiftCard")
      }
      commit("setState", { doingTransaction: false })
    },
    resetState({ commit, dispatch }) {
      dispatch("setMerchantInvalid");
      dispatch("setGiftCardInvalid");
      dispatch("setMerchantCode", "");
      dispatch("setGiftCardCode", "");
      commit("setState", { giftCardAmount: 0 });
      commit("setState", { amountToDeduct: NaN });
    }
  },
  modules: {}
});
