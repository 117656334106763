import axios from "axios";
export const checkMerchant = async merchantCode => {
  const data = {
    merchantQr: merchantCode
  }
  
  const res = await axios.post('/verify/merchant', data);
  console.log(res)
  return res.status === 200;
};

export const checkGiftCard = async (merchantCode, giftCardCode) => {
  const data = {
    merchantQr: merchantCode,
    giftCardQr: giftCardCode
  }
  
  const res = await axios.post(`/verify/giftcard`, data);
  console.log(res);
  if (res.status === 200) return res.data;

  return false;
};

export const sendTransaction = async (merchantCode, giftcardCode, amount) => {
  const res = await axios.post("/create_transaction", {
    merchantQr: merchantCode,
    giftCardQr: giftcardCode,
    amount
  });

  if (res.status !== 200) return false;
  return res.data;
};
