var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{attrs:{"app":"","color":"white","dense":"","elevation":"2"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-img',{staticClass:"shrink mr-2",attrs:{"alt":"Alfa-Zet Logo","contain":"","src":require("@/assets/alfa-zet.png"),"transition":"scale-transition","width":"40"}}),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.headerTitle))])],1),_c('v-spacer'),_c('span',[_vm._v(_vm._s(_vm.packageVersion))])],1),_c('v-main',[_c('router-view')],1),_c('v-scale-transition',[(_vm.giftCardValid && _vm.merchantValid)?_c('v-footer',{staticClass:"ma-2",attrs:{"fixed":"","rounded":"","dense":"","elevation":"4"}},[_c('div',{staticClass:"d-flex flex-column justify-center align-center",staticStyle:{"width":"100%"}},[_c('v-form',{staticStyle:{"width":"100%"},on:{"submit":function($event){$event.preventDefault();return _vm.setShowTransactionConfirmationDialog(true)}}},[_c('v-text-field',{staticStyle:{"width":"100%"},attrs:{"type":"number","label":"Bedrag","value":isNaN(_vm.amountToDeduct) ? '' : _vm.amountToDeduct,"rules":[
                function (v) { return isNaN(v) || v * 100 <= _vm.giftCardAmount ||
                  ("Bedrag te hoog (max. " + (_vm.$options.filters.currency(
                    _vm.giftCardAmount / 100
                  )) + ")"); },
                function (v) { return isNaN(v) || v >= 0 || "Er kunnen enkel positieve bedragen afgeschreven worden."; },
                function (v) { return isNaN(v) || v < 1000 || "Er kan maximaal € 1000.00 ineens afgeschreven worden."; }
              ],"disabled":!(_vm.giftCardValid && _vm.merchantValid),"prefix":"€","autocomplete":"off","min":"0","max":"1000","step":"0.01"},on:{"input":_vm.setAmountToDeduct}})],1),_c('v-spacer'),_c('v-btn',{staticStyle:{"width":"100%"},attrs:{"disabled":!(_vm.merchantValid && _vm.giftCardValid && _vm.amountToDeduct > 0) ||
          _vm.amountToDeduct * 100 > _vm.giftCardAmount,"color":"primary","loading":_vm.doingTransaction},on:{"click":function($event){return _vm.setShowTransactionConfirmationDialog(true)}}},[_vm._v("Ontwaarden ")])],1)]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }