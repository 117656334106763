<template>
  <v-app>
    <v-app-bar app color="white" dense elevation="2">
      <div class="d-flex align-center">
        <v-img
            alt="Alfa-Zet Logo"
            class="shrink mr-2"
            contain
            src="@/assets/alfa-zet.png"
            transition="scale-transition"
            width="40"
        />
        <v-toolbar-title>{{ headerTitle }}</v-toolbar-title>
      </div>

      <v-spacer></v-spacer>
      <span>{{ packageVersion }}</span>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
    <v-scale-transition>

      <v-footer fixed rounded dense class="ma-2" elevation="4" v-if="giftCardValid && merchantValid">

        <div class="d-flex flex-column justify-center align-center" style="width: 100%">
          <v-form @submit.prevent="setShowTransactionConfirmationDialog(true)" style="width: 100%">

            <v-text-field
                style="width:100%;"
                type="number"
                label="Bedrag"
                :value="isNaN(amountToDeduct) ? '' : amountToDeduct"
                @input="setAmountToDeduct"
                :rules="[
                  v => isNaN(v) || v * 100 <= giftCardAmount ||
                    `Bedrag te hoog (max. ${$options.filters.currency(
                      giftCardAmount / 100
                    )})`,
                  v => isNaN(v) || v >= 0 || `Er kunnen enkel positieve bedragen afgeschreven worden.`,
                  v => isNaN(v) || v < 1000 || `Er kan maximaal € 1000.00 ineens afgeschreven worden.`
                ]"
                :disabled="!(giftCardValid && merchantValid)"
                prefix="€"
                autocomplete="off"
                min="0"
                max="1000"
                step="0.01"
            ></v-text-field>
          </v-form>
          <v-spacer></v-spacer>
          <v-btn
              :disabled="
          !(merchantValid && giftCardValid && amountToDeduct > 0) ||
            amountToDeduct * 100 > giftCardAmount
        "
              @click="setShowTransactionConfirmationDialog(true)"
              color="primary"
              style="width: 100%"
              :loading="doingTransaction"
          >Ontwaarden
          </v-btn
          >
        </div>
      </v-footer>
    </v-scale-transition>
  </v-app>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex"

export default {
  name: "App",

  data: () => ({
    //
  }),
  computed: {
    ...mapState([
      "headerTitle",
      "packageVersion",
      "merchantValid",
      "giftCardValid",
      "showTransactionConfirmationDialog",
      "showGiftCardScannedAsMerchantDialog",
      "showInvalidMerchantScanDialog",
      "showInvalidGiftCardScanDialog",
      "showShopNotMatchingDialog",
      "showGenericErrorDialog",
      "fetchingGiftCard",
      "fetchingMerchant",
      "giftCardAmount",
      "showTransactionSuccessfulDialog",
      "showTransactionAmountTooHighDialog",
      "doingTransaction",
      "showMerchantScannedAsGiftCardDialog",
      "amountToDeduct",
      "showTransactionFailedDialog",
    ]),
    ...mapGetters(["showingDialog"])
  },
  methods: {
    ...mapActions([
      "setMerchantCode",
      "setGiftCardCode",
      "resetState",
      "processTransaction",
      "resetGiftCard"
    ]),
    ...mapMutations([
      "setShowTransactionConfirmationDialog",
      "setState",
      "setAmountToDeduct"
    ])
  }
};
</script>
